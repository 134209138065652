import { localStorageUtil } from './lib/storage'

export const autogenPasswordUtil = (() => {
  const autogenKey = 'st_autogen'
  return {
    set: () => {
      localStorageUtil.setItem(autogenKey, 'ok')
    },
    get: () => localStorageUtil.getItem(autogenKey),
    remove: () => {
      localStorageUtil.removeItem(autogenKey)
    },
  }
})()
