import { sessionStorageUtil } from '../storage'

const requestedUrlUtil = (() => {
  const requestedUrlFlag = 'st_req_url'
  return {
    setRequestedUrl: url => sessionStorageUtil.setItem(requestedUrlFlag, url),
    getRequestedUrl: () => sessionStorageUtil.getItem(requestedUrlFlag),
    deleteRequestedUrl: () => sessionStorageUtil.removeItem(requestedUrlFlag),
  }
})()

export default requestedUrlUtil
