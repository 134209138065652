import jwtDecode from 'jwt-decode'
import { API_ERROR_MESSAGE_DEFAULT } from '@skilltype/services/constants'
import { autogenPasswordUtil } from '@skilltype/ui/utils'

const appReleaseStage = process.env.REACT_APP_RELEASE_STAGE
const apiURL = process.env.REACT_APP_API_URL
const withGoogleURL = process.env.REACT_APP_SIGN_IN_WITH_GOOGLE
const withMicrosoftURL = process.env.REACT_APP_SIGN_IN_WITH_MICROSOFT

function getCookieDomain() {
  return appReleaseStage !== 'local'
    ? new URL(apiURL).hostname.replace('api', '')
    : ''
}

function getCookieName() {
  return appReleaseStage.startsWith('prod')
    ? 'skilltype'
    : `skilltype-${appReleaseStage}`
}
const cookieName = getCookieName()
const cookieDomain = getCookieDomain()

export function setAuthCookie(value) {
  const { exp } = jwtDecode(value)
  const d = new Date(exp * 1000)
  document.cookie = `${cookieName}=${value};path=/;SameSite=Lax;Secure;expires=${d.toGMTString()};domain=${cookieDomain}`

  /* develblock:start */
  if (appReleaseStage === 'cypress') {
    window.localStorage.setItem('cypress', value)
  }
  /* develblock:end */
}

export function getAuthCookie() {
  /* develblock:start */
  if (appReleaseStage === 'cypress') {
    return window.localStorage.getItem('cypress')
  }
  /* develblock:end */

  const v = document.cookie.match(`(^|;) ?${cookieName}=([^;]*)(;|$)`)
  return v ? v[2] : null
}

export function deleteAuthCookie() {
  // set expire date to a past date
  const old = new Date(1970)
  // clear existing cookie created with no domain specified
  document.cookie = `${cookieName}='';path=/;expires=${old.toGMTString()}`
  document.cookie = `${cookieName}='';path=/;expires=${old.toGMTString()};domain=${cookieDomain}`

  /* develblock:start */
  if (appReleaseStage === 'cypress') {
    window.localStorage.removeItem('cypress')
  }
  /* develblock:end */
}

export async function login({ activationToken, username, password }) {
  const response = await fetch(`${apiURL}/signin`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      activationToken,
      userName: username,
      password,
    }),
  })
  const { status } = response
  if (status === 200) {
    const token = await response.text()
    return { status, token }
  }
  if (status === 401) {
    return { status, token: '' }
  }
  const body = await response.text()
  throw new Error(body)
}

export async function signup(values) {
  const response = await fetch(`${apiURL}/signup`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(values),
  })
  if (response.status >= 500) {
    throw new Error(API_ERROR_MESSAGE_DEFAULT)
  }
  const { message, token } = await response.json()
  if (token) {
    setAuthCookie(token)
    autogenPasswordUtil.set()
  } else {
    throw new Error(message || API_ERROR_MESSAGE_DEFAULT)
  }
}

export async function getOrganizationInvite(token) {
  const response = await fetch(`${apiURL}/signup/${token}`, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  })
  const { status } = response
  if (status === 200) {
    return response.json()
  }

  let message = API_ERROR_MESSAGE_DEFAULT
  if (status >= 404) {
    message =
      'We’re sorry, but this link is unknown. Please contact your administrator.'
  }
  return { message, error: true }
}

export async function registerUser({ email, firstName, lastName, token }) {
  const response = await fetch(`${apiURL}/signup/${token}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ email, firstName, lastName }),
  })

  const { status } = response
  let message = ''
  let error = false
  let notModified = false
  if (status === 201) {
    message = 'Welcome! Check your email for an invitation from Skilltype.'
    // 200 - user exists, joined org; 304 - user already a member;
  } else if (status === 200 || status === 304) {
    message =
      'A user with this email already exists. Please sign in using the link below.'
    error = true
    if (status === 304) {
      notModified = true
    }
  } else {
    throw new Error(API_ERROR_MESSAGE_DEFAULT)
  }

  return { message, error, notModified }
}

export async function confirmEmailChange(token) {
  const response = await fetch(`${apiURL}/user/email/${token}/confirm`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${getAuthCookie()}`,
    },
  })
  const { status } = response
  if (status === 200) {
    const newJWT = await response.text()
    if (newJWT) {
      setAuthCookie(newJWT)
    }
    return
  }
  const { message } = await response.json()
  const error = new Error(message)
  error.status = status
  throw error
}
