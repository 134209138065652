const sessionStorageHandler = (cb, errResponse) => {
  try {
    return cb(window.sessionStorage)
  } catch (e) {
    return errResponse || false
  }
}

const localStorageHandler = (cb, errResponse) => {
  try {
    return cb(window.localStorage)
  } catch (e) {
    return errResponse || false
  }
}

const localStorageUtil = (() => ({
  setItem: (name, value) => localStorageHandler(ls => ls.setItem(name, value)),
  getItem: name => localStorageHandler(ls => ls.getItem(name)),
  removeItem: name => localStorageHandler(ls => ls.removeItem(name)),
}))()
const sessionStorageUtil = (() => ({
  setItem: (name, value) =>
    sessionStorageHandler(ss => ss.setItem(name, value)),
  getItem: name => sessionStorageHandler(ss => ss.getItem(name)),
  removeItem: name => sessionStorageHandler(ss => ss.removeItem(name)),
}))()

export {
  sessionStorageHandler,
  sessionStorageUtil,
  localStorageHandler,
  localStorageUtil,
}
