import { localStorageUtil } from '../storage'

const tokenKey = 'st-i-token'
const organizationKey = 'st-o-name'
const getSuccessMessageForName = name => `You have joined ${name}`
/*
  token is present when a join request still needs to be made
  name is present when when the join request was successful
 */
const joinOrganizationUtil = (() => ({
  setInviteToken: token => {
    localStorageUtil.setItem(tokenKey, token)
    localStorageUtil.removeItem(organizationKey)
  },
  setInviteTokenFromPath: pathname => {
    const token = (pathname || '').split('/').pop()
    if (token) {
      localStorageUtil.setItem(tokenKey, token)
    }
    localStorageUtil.removeItem(organizationKey)
  },
  setOrganizationName: name => {
    localStorageUtil.setItem(organizationKey, name)
    localStorageUtil.removeItem(tokenKey)
  },
  getInviteToken: () => {
    const token = localStorageUtil.getItem(tokenKey)
    if (token) {
      localStorageUtil.removeItem(tokenKey)
    }
    return token
  },
  getSuccessMessage: () => {
    const name = localStorageUtil.getItem(organizationKey)
    if (name) {
      localStorageUtil.removeItem(organizationKey)
      return getSuccessMessageForName(name)
    }
    return name
  },
  getSuccessMessageForName,
  hasOrganizationNameSet: () => !!localStorageUtil.getItem(organizationKey),
}))()

export default joinOrganizationUtil
